.title {
  composes: title from './ReviewsSection.module.css';
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 48px;
}

.description {
  composes: description from './ReviewsSection.module.css';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 96px;
}

.gridContainer {
  @media (min-width: 768px) and (max-width: 1440px) {
    margin-top: 4rem;
  }
}

.grid {
  composes: grid from './ReviewsSection.module.css';
  @media (min-width: 768px) and (max-width: 1440px) {
    grid-template-columns: repeat(2, 375px);
  }
}

.gridItem {
  composes: gridItem from './ReviewsSection.module.css';
  background-color: var(--section-background-color);
}

.starRating {
  composes: starRating from './ReviewsSection.module.css';
  filter: var(--icon-filter-color);
}

.starRating img {
  width: 20px;
  height: 20px;
}

.text {
  composes: text from './ReviewsSection.module.css';
}

.author {
  composes: author from './ReviewsSection.module.css';
}

.avatars {
  margin-bottom: 24px;
}

@media (max-width: 600px) {
  .title {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 32px;
  }

  .description {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 50px;
  }

  .text {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .author {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
