@import url('https://use.typekit.net/aeh0dkr.css');

:root {
  --font-poppins: 'Poppins';
  --primary-900-color: #24236a;
  --accent-color: #ffcf67;
  --text-accent-color: var(--primary-900-color);
  --text-body-color: #fff;
  --text-header-color: #fff;
  --background-color: var(--primary-900-color);

  --header-divider-color: #37367d;
  --header-icon-filter-color: var(--icon-filter-color);
  --selected-color: var(--primary-900-color);

  --button-background-color: #ffcf67;
  --button-border-radius: 12px;

  --partners-divider-color: #d9d9d9;

  --footer-link-color: var(--primary-900-color);
  --mobile-footer-link-color: var(--primary-900-color);

  --select-label-color: var(--primary-900-color);
  --select-value-color: var(--primary-900-color);

  --icon-filter-color: brightness(0) saturate(100%) invert(64%) sepia(9%) saturate(5054%) hue-rotate(5deg) brightness(101%) contrast(103%);

  --header-icon-filter-color: brightness(0) saturate(100%) invert(96%) sepia(100%) saturate(0%) hue-rotate(83deg) brightness(104%)
    contrast(104%);

  --section-background-color: #f4e7d7;

  font-feature-settings: 'ss01' on;
}

main {
  font-family: var(--font-poppins);
  color: var(--primary-900-color);
}

header {
  background-color: var(--primary-900-color);
}

.heading1,
.heading2,
.heading3,
.heading4 {
  font-weight: 700;
}

.ReviewsSection_section__z1hw9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReviewsSection_title__MQTw9 {
  margin-bottom: 3rem;
}

.ReviewsSection_description__E3q72 {
  text-align: center;
  max-width: 50rem;
}

.ReviewsSection_grid__qe0e8 {
  display: grid;
  grid-template-columns: repeat(3, 412px);
  grid-auto-rows: auto;
  grid-auto-flow: row dense;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  align-items: start;
}

.ReviewsSection_starRating__RO_dE {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.ReviewsSection_gridItem__b7sA_ {
  background-color: #fef9f4;
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.ReviewsSection_middleColumn__wn8tW {
  transform: translateY(-35px);
  z-index: 1;
}

.ReviewsSection_gridContainer__p_giz {
  display: flex;
  align-items: center;
  height: 40rem;
  margin-top: 6.25rem;
}

.ReviewsSection_text__9q3g4,
.ReviewsSection_author__8I9C_ {
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.18px;
}

.ReviewsSection_author__8I9C_ {
  font-weight: 700;
}

@media (max-width: 768px) {
  .ReviewsSection_grid__qe0e8 {
    grid-template-columns: 1fr;
  }

  .ReviewsSection_title__MQTw9 {
    margin-bottom: 2rem;
  }

  .ReviewsSection_gridContainer__p_giz {
    height: auto;
    margin-top: 3.5rem;
  }

  .ReviewsSection_middleColumn__wn8tW {
    transform: none;
    display: none; /* show less content on mobile */
  }

  .ReviewsSection_text__9q3g4,
  .ReviewsSection_author__8I9C_ {
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
  }
}

.ReviewsSectionCZ_title__njWaj {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 48px;
}

.ReviewsSectionCZ_description__sUQrD {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 96px;
}

.ReviewsSectionCZ_gridContainer__Exk42 {
  @media (min-width: 768px) and (max-width: 1440px) {
    margin-top: 4rem;
  }
}

.ReviewsSectionCZ_grid__AGrkZ {
  @media (min-width: 768px) and (max-width: 1440px) {
    grid-template-columns: repeat(2, 375px);
  }
}

.ReviewsSectionCZ_gridItem__sZ4DA {
  background-color: var(--section-background-color);
}

.ReviewsSectionCZ_starRating__FVEUm {
  filter: var(--icon-filter-color);
}

.ReviewsSectionCZ_starRating__FVEUm img {
  width: 20px;
  height: 20px;
}

.ReviewsSectionCZ_text__zt4qr {
}

.ReviewsSectionCZ_author__wBekk {
}

.ReviewsSectionCZ_avatars__lfF0X {
  margin-bottom: 24px;
}

@media (max-width: 600px) {
  .ReviewsSectionCZ_title__njWaj {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 32px;
  }

  .ReviewsSectionCZ_description__sUQrD {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 50px;
  }

  .ReviewsSectionCZ_text__zt4qr {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .ReviewsSectionCZ_author__wBekk {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/lp/_next/static/media/f10b8e9d91f3edcb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/lp/_next/static/media/8888a3826f4a3af4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/a1386beebedccca4-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/b957ea75a84b6ea7-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_14c039';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_14c039 {font-family: '__Poppins_14c039', '__Poppins_Fallback_14c039';font-style: normal
}.__variable_14c039 {--font-poppins: '__Poppins_14c039', '__Poppins_Fallback_14c039'
}

